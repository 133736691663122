import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import JSCookie from 'js-cookie';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import {
  ERR_MESSAGE,
  OTP_COUNTDOWN_TIME,
  METAMASK_REDIRECT_TO_APP_LINK,
  DGG_PRIVACY_POLICY_URL,
  DGG_REDIRECT_TO_APP_LINK,
  TIME_SECOND_TO_MILISECOND,
  PRODUCT_ENV,
} from '../../../constants';
import AuthApi from '../../../services/authen';
import { useAppDispatch } from '../../../store';
import { hideLoading, showLoading } from '../../../store/features/loadingPage';
import useMessage from '../toast/UseMessage';
import TButton from '../Button/index';
import './styles.scss';
import LoginBtn from '../Button/LoginBtn';
import EmailIcon from '../../icons/EmailIcon';
import DggIcon from '../../icons/DggIcon';
import MetamaskIcon from '../../icons/MetamaskIcon';
import WalletConnectIcon from '../../icons/WalletConnectIcon';
import CloseDialogIcon from '../../icons/CloseDialogIcon';
import PrizeBoxIcon from '../../icons/PrizeBoxIcon';
import PrimaryInput from '../Input/PrimaryInput';
import LoginEmailInput from '../Input/LoginEmailInput';
import PrimaryInputCheckbox from '../Input/PrimaryInputCheckbox';
import ShowQrcode from '../ShowQrCode/ShowQrCode';
import useDevice from '../../../hooks/useDevice';
import { QR_CODE_IMG } from '../../../../assets/imgs';
import Loading from '../Loading/Loading';
import { validateNewEmail } from '../../../utils/validator';
import processDataLogin from '../../../lib/processDataLogin';
import { toggleModalOpen, toggleModalClose } from '../../../store/features/openModalLogin';
import LoginWithWalletConnect from '../ShowQrCode/LoginWithWalletConnect';

import { metaMask } from '../../../connectors/metaMask';
import { useAuthContext } from '../../../context/AuthContext';
import { ConnectorNames } from '../../../lib/connectors';

function NewModalLogin(props) {
  // eslint-disable-next-line object-curly-newline
  const { show } = props;
  const dispatch = useAppDispatch();
  const { openMessageError } = useMessage();
  const loginRef = useRef(null);
  const history = useHistory();
  const { pathname } = history.location;
  const path = '/';

  const context = useWeb3React();
  const { connector, provider, account, chainId: selectedChainId } = context;
  const [userEmail, setUserEmail] = useState('');
  const isMobile = useDevice();
  const [enableSendOtp, setEnableSendOtp] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [isReadPrivacy, setIsReadPrivacy] = useState(false);
  const [showRetryBtn, setShowRetryBtn] = useState(false);
  const [referralCode, setReferralCode] = useState('');
  const [otp, setOtp] = useState('');
  const { checkLogin, checkCreatedProfile, checkLinkedEmailAddress } = useAuthContext();
  const [loginMethod, setLoginMethod] = useState(ConnectorNames.Email);
  const { t } = useTranslation();

  useEffect(() => {
    function handleClickOutside(event) {
      if (loginRef.current && !loginRef.current.contains(event.target)) {
        dispatch(toggleModalClose());
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [loginRef]);

  useEffect(() => {
    if (validateNewEmail(userEmail)) {
      setEnableSendOtp(true);
    } else {
      setEnableSendOtp(false);
    }
  }, [userEmail]);

  useEffect(() => {
    const timeoutId = setInterval(() => {
      if (timeLeft > 0) {
        setTimeLeft(timeLeft - 1);
      }
    }, TIME_SECOND_TO_MILISECOND);
    return () => clearInterval(timeoutId);
  }, [timeLeft]);

  const handleSendOtp = async () => {
    dispatch(showLoading());
    try {
      const formData = {
        email: userEmail,
      };
      await AuthApi.register(formData);
      setTimeLeft(OTP_COUNTDOWN_TIME);
    } catch (error) {
      openMessageError(error?.response?.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };

  const handleLoginEmail = async () => {
    dispatch(showLoading());
    try {
      const formData = {
        email: userEmail,
        token: otp,
      };
      if (referralCode) {
        formData.referralCode = referralCode;
      }
      const res = await AuthApi.loginEmail(formData);
      const refreshExpireIn = new Date(new Date().getTime() + res?.data?.refresh_expires_in * 1000);

      JSCookie.set('email', userEmail, {
        expires: refreshExpireIn,
      });
      JSCookie.set('walletAccount', res?.data?.walletAddress ? res?.data?.walletAddress : 0, {
        expires: refreshExpireIn,
      });
      JSCookie.set(
        'expiresIn',
        Number(new Date().getTime()) + Number(res?.data?.refresh_expires_in * 1000),
        { expires: refreshExpireIn },
      );
      JSCookie.set(
        'isCreateProfile',
        res?.data?.isCreateProfile ? res?.data?.isCreateProfile : false,
        {
          expires: refreshExpireIn,
        },
      );
      JSCookie.set(
        'isLinkedWithEmaiAddress',
        res?.data?.isLinkedEmaiAddress ? res?.data?.isLinkedEmaiAddress : false,
        {
          expires: refreshExpireIn,
        },
      );

      processDataLogin(res?.data);
      dispatch(toggleModalClose());
      checkLogin();
      checkCreatedProfile();
      checkLinkedEmailAddress();
      localStorage.setItem('connector', loginMethod);
    } catch (error) {
      openMessageError(error?.response?.data?.message || ERR_MESSAGE);
    } finally {
      setOtp('');
      dispatch(hideLoading());
      dispatch(toggleModalClose());
    }
  };

  const handleLoginWallet = async () => {
    dispatch(showLoading());
    try {
      const date = new Date();
      const signer = provider.getSigner(account);
      const message = `${date.getTime()}.${account}`;
      const hashMessage = ethers.utils.hashMessage(message);
      const signature = await signer.signMessage(hashMessage);

      const payload = {
        walletAddress: account,
        message,
        signature,
        chainId: selectedChainId,
      };
      if (referralCode) {
        payload.referralCode = referralCode;
      }
      const res = await AuthApi.login(payload);
      if (res?.status >= 200 && res?.status < 300) {
        const refreshExpireIn = new Date(
          new Date().getTime() + res?.data?.refresh_expires_in * 1000,
        );
        JSCookie.set('email', res?.data?.email, {
          expires: refreshExpireIn,
        });
        JSCookie.set('walletAccount', account, {
          expires: refreshExpireIn,
        });
        JSCookie.set(
          'expiresIn',
          Number(new Date().getTime()) + Number(res?.data?.refresh_expires_in * 1000),
          { expires: refreshExpireIn },
        );
        JSCookie.set(
          'isCreateProfile',
          res?.data?.isCreateProfile ? res?.data?.isCreateProfile : false,
          {
            expires: refreshExpireIn,
          },
        );
        JSCookie.set(
          'isLinkedWithEmaiAddress',
          res?.data?.isLinkedEmaiAddress ? res?.data?.isLinkedEmaiAddress : false,
          {
            expires: refreshExpireIn,
          },
        );
        localStorage.setItem('connector', loginMethod);
        processDataLogin(res?.data);
        dispatch(toggleModalClose());
        checkLogin();
        checkCreatedProfile();
        checkLinkedEmailAddress();

        toast.success(t('login.logSuccess'));
      } else {
        openMessageError(
          error?.data?.message ||
            error?.reason ||
            error?.message ||
            'Opps! Something went wrong...',
        );
      }
    } catch (error) {
      if (loginMethod === ConnectorNames.Metamask) {
        setShowRetryBtn(true);
      }
      if (connector?.deactivate) {
        connector.deactivate();
      } else {
        connector.resetState();
      }
      openMessageError(
        error?.data?.message || error?.reason || error?.message || 'Opps! Something went wrong...',
      );
    } finally {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    if (account && provider) {
      handleLoginWallet().catch((error) => {
        openMessageError('Error: ', error);
        // eslint-disable-next-line
        console.log('handleLoginWallet function error: ', error);
      });
    }
  }, [account, provider]);

  const handleLoginMetamask = () => {
    const { ethereum } = window;
    const hostName = window.location.hostname;

    if (ethereum) {
      setShowRetryBtn(false);
      metaMask.activate();
      setLoginMethod(ConnectorNames.Metamask);
    }
    if (isMobile && !ethereum) {
      window.open(`${METAMASK_REDIRECT_TO_APP_LINK}${hostName}`, '_blank', 'noopener,noreferrer');
    }
  };

  const handleLoginDggWallet = () => {
    const { ethereum } = window;
    const hostName = window.location.hostname;

    if (!isMobile) {
      setLoginMethod(ConnectorNames.DggWalletDesktop);
      return;
    }

    if (isMobile && ethereum) {
      setLoginMethod(ConnectorNames.DggWalletMobile);
      metaMask.activate();
    } else {
      window.open(`${DGG_REDIRECT_TO_APP_LINK}${hostName}`, '_blank', 'noopener,noreferrer');
    }
  };

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const referralCode = params.get('referralCode');
    const token = JSCookie.get('refreshToken');
    if (referralCode && !token) {
      setReferralCode(referralCode);
      dispatch(toggleModalOpen());
    }
  }, [location.search]);

  return (
    <div
      className={show ? 'new-modal-login show-modal-login hide-scrollbar' : 'new-modal-login'}
      ref={loginRef}
      style={path === pathname ? { left: '50%', zIndex: '1000' } : { zIndex: '1000' }}
    >
      <div className="left-block-login">
        <p className="title-select-login-method">{t('login.selectMethod')}</p>
        <LoginBtn
          onClick={() => setLoginMethod(ConnectorNames.Email)}
          className={`btn-login-email ${
            loginMethod === ConnectorNames.Email ? 'btn-has-border' : 'btn-no-border'
          }`}
        >
          <p>Email</p>
          <EmailIcon />
        </LoginBtn>
        <p className="text-or">{t('login.or')}</p>
        <div className={`${PRODUCT_ENV === 'dev' ? 'other-btn-login-dev' : 'other-btn-login'}`}>
          <LoginBtn
            onClick={handleLoginDggWallet}
            className={`dgg-login ${
              loginMethod === ConnectorNames.DggWalletDesktop ||
              loginMethod === ConnectorNames.DggWalletMobile
                ? 'btn-has-border'
                : 'btn-no-border'
            }`}
          >
            <p className="title-login-method">{t('wallet.defaut')}</p>
            <DggIcon />
          </LoginBtn>
          {/* Hidden btn to use later  */}
          {PRODUCT_ENV === 'dev' && (
            <>
              <LoginBtn
                onClick={handleLoginMetamask}
                className={`metamask-login ${
                  loginMethod === ConnectorNames.Metamask ? 'btn-has-border' : 'btn-no-border'
                }`}
              >
                <p className="title-login-method">Metamask</p>
                <MetamaskIcon />
              </LoginBtn>

              <LoginBtn
                onClick={() => setLoginMethod(ConnectorNames.WalletConnect)}
                className={`wallet-connect-login ${
                  loginMethod === ConnectorNames.WalletConnect ? 'btn-has-border' : 'btn-no-border'
                }`}
              >
                <p className="title-login-method">Wallet Connect</p>
                <WalletConnectIcon />
              </LoginBtn>
            </>
          )}
        </div>
      </div>
      <div className="right-block-login">
        <div className="right-title-wrapper">
          <div className="invite-code-title">
            <p className="right-title">{t('login.inviteCode')}</p>
            <PrizeBoxIcon />
          </div>
          <div className="cursor-pointer" aria-hidden onClick={() => dispatch(toggleModalClose())}>
            <CloseDialogIcon />
          </div>
        </div>
        <PrimaryInput
          className="invite-code-input"
          note={
            <p className="note-input">
              <span className="text-dangerous">*</span> {t('login.warning')}
            </p>
          }
          placeholder={t('login.placeHolder.enterCode')}
          onChange={(e) => setReferralCode(e.target.value)}
          value={referralCode || ''}
        />
        {loginMethod === ConnectorNames.Email && (
          <div className="login-email-wrapper">
            <p className="login-title">{t('login.withEmail.title')}</p>
            <p className="login-sub-title">{t('login.withEmail.subTitle')}</p>
            <div className="login-input-wrapper">
              <LoginEmailInput
                onChange={(event) => setUserEmail(event.target.value)}
                enableSendOtp={enableSendOtp}
                handleSendOtp={handleSendOtp}
                timeLeft={timeLeft}
              />
              <PrimaryInput
                title="OTP"
                placeholder={t('login.withEmail.sendOTP', { seconds: `${OTP_COUNTDOWN_TIME}s` })}
                onChange={(e) => setOtp(e.target.value)}
                value={otp}
              />

              <PrimaryInputCheckbox
                accessoriesRight={
                  <p className="privacy-confirm">
                    {t('privacy.confirm')}{' '}
                    <a href={DGG_PRIVACY_POLICY_URL} target="_blank" rel="noreferrer">
                      <span className="privacy-policy">{t('privacy.policy')}</span>
                    </a>
                    .
                  </p>
                }
                onChange={() => setIsReadPrivacy(!isReadPrivacy)}
                checked={isReadPrivacy}
              />
            </div>
            <TButton
              disabled={!userEmail || otp.length < 6 || !isReadPrivacy}
              title={t('login.loginButton')}
              onClick={handleLoginEmail}
              width="full-width"
            />
          </div>
        )}
        {loginMethod === ConnectorNames.DggWalletDesktop && <ShowQrcode />}
        {loginMethod === ConnectorNames.WalletConnect && (
          <LoginWithWalletConnect handleSelectWalletConnect={handleSelectWalletConnect} />
        )}
        {loginMethod === ConnectorNames.Metamask && (
          <div className="metamask-login-wrapper">
            <img src={QR_CODE_IMG.metamaskConnect} alt="metamask-logo" className="metamask-width" />
            <p className="txt-metamask-open">{t('login.withMetaMask.open')}</p>
            <p className="txt-metamask-confirm">{t('login.withMetaMask.confirm')}</p>
            {!showRetryBtn ? (
              <Loading />
            ) : (
              <div onClick={handleLoginMetamask} className="retry-btn" aria-hidden="true">
                {t('alert.retry')}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default NewModalLogin;
